import React, {useState} from 'react'
import Terminal, {ColorMode, TerminalInput, TerminalOutput} from 'react-terminal-ui';


export default function TerminalController(props) {
    const [colorMode, setColorMode] = useState(ColorMode.Dark);
    const [lineData, setLineData] = useState([
        <TerminalOutput>Hello World!&#128075;</TerminalOutput>,
        <TerminalOutput></TerminalOutput>
    ]);

    function toggleColorMode(e) {
        e.preventDefault();
        setColorMode(colorMode === ColorMode.Light ? ColorMode.Dark : ColorMode.Light);
    }

    function onInput(input) {
        let ld = [...lineData];
        ld.push(<TerminalInput>{input}</TerminalInput>);
        if (input.toLocaleLowerCase().trim() === 'view-source') {
            window.open('https://github.com/jonmbake/react-terminal-ui', '_blank');
        } else if (input.toLocaleLowerCase().trim() === 'view-react-docs') {
            window.open('https://reactjs.org/docs/getting-started.html', '_blank');
        } else if (input.toLocaleLowerCase().trim() === 'clear') {
            ld = [];
        } else if (input) {
            ld.push(<TerminalOutput>Unrecognized command</TerminalOutput>);
        }
        setLineData(ld);
    }

    const btnClasses = ['btn'];
    if (colorMode === ColorMode.Light) {
        btnClasses.push('btn-dark');
    } else {
        btnClasses.push('btn-light');
    }
    return (
        <div className="container" style={{'width': '100%', 'height': '100%'}}>
            <div className="d-flex flex-row-reverse p-2">
                <button className={btnClasses.join(' ')}
                        onClick={toggleColorMode}>Enable {colorMode === ColorMode.Light ? 'Dark' : 'Light'} Mode
                </button>
            </div>
            <Terminal name='React Terminal UI' colorMode={colorMode} onInput={onInput}>
                {lineData}
            </Terminal>
        </div>
    )
};
